import React, { useEffect, useState, useRef } from 'react'
import './Blank.styl'
import Animotion from './../Animotion'
//import { useStaticQuery, graphql } from 'gatsby'
//import Img from 'gatsby-image'

const Blank = props => {
  const [boxWidth, setBoxWidth] = useState('100%')
  const boxRef = useRef(null)
  useEffect(() => {
    const calculateBoxWidth = () => {
      setBoxWidth(boxRef.current ? boxRef.current.clientWidth : '100%')
    }
    setTimeout(() => {
      calculateBoxWidth()
    }, 200)
    window.addEventListener('resize', () => calculateBoxWidth())
  }, [])
  return (
    <div ref={boxRef} className={`Blank ${props.onHeight && 'vBlank'}`}>
      <img src={props.sizer} alt='blank' />
      <div>
        <Animotion>
          <figure
            style={{
              width: boxWidth,
            }}
          >
            <img
              alt=''
              src={props.image}
              className='object-cover w-full h-full'
            />
          </figure>
        </Animotion>
      </div>
      <aside className='Tilt-inner'>{props.caption}</aside>
    </div>
  )
}

// const Image = props => {
//   const { src } = props
//   const data = useStaticQuery(
//     graphql`
//       query allTheImagesQuery {
//         allImageSharp {
//           edges {
//             node {
//               fluid(maxWidth: 1000) {
//                 ...GatsbyImageSharpFluid
//                 originalName
//               }
//             }
//           }
//         }
//       }
//     `,
//   )
//
//   const TheImageYouWant = data.allImageSharp.edges
//     .filter(edge => edge.node.fluid.originalName === src)
//     .map(myImage => <Img {...props} fluid={myImage.node.fluid} />)
//
//   return <>{TheImageYouWant}</>
// }

export default Blank
