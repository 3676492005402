import React from 'react'
import './Layouts.styl'

const Flex = props => (
  <div className='Flex'>
    {props.children}
  </div>
)

const Row = props => (
  <div className='Row'>
    {props.children}
  </div>
)

export {Row,Flex}
