import React from 'react'
import LinkLang from './../../components/LinkLang'
import './Button.styl'

const LineButton = props => (
  <button type='button' className='LineButton'>
    {props.to && <LinkLang to={props.to} />}
    <span>{props.children}</span>
  </button>
)

const RoomButton = props => (
  <button type='button' className='LineButton'>
    <a target='_blank' href={props.to} />
    <span>{props.children}</span>
  </button>
)

export { LineButton, RoomButton }
