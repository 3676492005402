import React from 'react'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions'
import PageHeading from '../UI/PageHeading/PageHeading'
import { Row } from '../UI/Layouts/Layouts'
import { Text, Title } from './../UI/Typing'
import Blank from '../UI/Blank/Blank'
import rectBlank from '../Assets/rectBlank.png'
import rectHBlank from '../Assets/rect.png'
import Animotion from '../UI/Animotion'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Parallax } from 'react-scroll-parallax'
import { RoomButton } from '../UI/Button/Button'
import './rooms.styl'

// import images
import HeadImg from './../Assets/images_pg/aboutus/as.jpg'
import About1 from './../Assets/aboutus/CORRIERE.jpg'
import About2 from './../Assets/aboutus/IES1.jpg'
import About11 from './../Assets/aboutus/blank.jpg'
import About4 from './../Assets/aboutus/IES3.jpg'
import About5 from './../Assets/aboutus/NOVICE.jpg'
import About6 from './../Assets/aboutus/QB.jpg'
import About7 from './../Assets/aboutus/TOP1.jpg'
import About8 from './../Assets/aboutus/TOP2.jpg'
import About9 from './../Assets/aboutus/WINEMAG1.jpg'
import About10 from './../Assets/aboutus/WINEMAG2.jpg'

const Line = () => <span className='Line' />

const RoomRow = props => (
  <div className={`RoomRow ${props.position}`}>
    <Row>
      <div className='RoomImg'>
        <Blank sizer={rectBlank} image={props.image} />
      </div>
      <div className='RoomDesc'>
        <Blank sizer={rectBlank} image={props.subimage} />
        <RoomInfo {...props} />
      </div>
    </Row>
  </div>
)

const RoomColumn = props => (
  <div className={`RoomColumn ${props.position}`}>
    <div>
      <div style={{ zIndex: 3, position: 'relative' }}>
        <Parallax offsetYMax={20} offsetYMin={-20}>
          <Blank sizer={rectHBlank} image={props.image} />
        </Parallax>
      </div>
      <div>
        <div>
          <Parallax slowerScrollRate offsetYMax={13} offsetYMin={-13}>
            <Blank sizer={rectBlank} image={props.subimage} />
          </Parallax>
        </div>
        <RoomInfo {...props} />
      </div>
    </div>
  </div>
)

const bookingLink =
  'https://www.booking.com/hotel/it/klanjscek-wine-amp-stay.it.html?aid=898224&app_hotel_id=4656073&checkin=2019-03-29&checkout=2019-03-30&from_sn=ios&group_adults=2&group_children=0&label=Share-7tffO1%401552551640&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA%2C'

const RoomInfo = props => (
  <aside>
    <Animotion>
      <Title>{props.title}</Title>
      <Line />
      <Text>{props.description}</Text>
    </Animotion>
  </aside>
)

const RoomPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        title={<FormattedMessage id='aboutus' />}
        subtitle={<FormattedMessage id='aboutDescr' />}
        text={<FormattedMessage id='aboutusDescr' />}
        image={HeadImg}
      />
      <section className='Rooms'>
        <Animotion>
          <RoomRow
            title={<FormattedMessage id='as1Title' />}
            position='left'
            image={About1}
            subimage={About11}
          />
        </Animotion>

        <Animotion>
          <RoomRow
            title={<FormattedMessage id='as2Title' />}
            position='left'
            image={About2}
            subimage={About4}
          />
        </Animotion>

        <Animotion>
          <RoomRow
            position='left'
            title={<FormattedMessage id='as3Title' />}
            image={About5}
            subimage={About11}
          />
        </Animotion>

        <Animotion>
          <RoomRow
            position='left'
            title={<FormattedMessage id='as4Title' />}
            image={About7}
            subimage={About8}
          />
        </Animotion>

        <Animotion>
          <RoomRow
            position='left'
            title={<FormattedMessage id='as5Title' />}
            image={About6}
            subimage={About11}
          />
        </Animotion>


        <Animotion>
          <RoomRow
            position='left'
            title={<FormattedMessage id='as6Title' />}
            image={About9}
            subimage={About10}
          />
        </Animotion>


      </section>
    </PageTransition>
  </Layout>
)

export default RoomPage
